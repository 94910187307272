
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'

@Component
export default class PublicRoleDeclaration extends Vue {
  @State(({ userState }) => userState.ticket) ticket!: any
  @State(({ userState }) => userState.affidavit) affidavit!: any
  @State(({ userState }) => userState.publicUserInfo) publicUserInfo!: any

  @Action sendAssistantsInfo!: Function

  /* ANSWERS MODELS*/
  /* public businessName = null;
  public hasTravel = null;
  public whereHasTravel = null;
  public hasContactWithTravelers = null;
  public whenHasContact = null;
  public hasFieber = null;
  public hasCough = null;
  public hasSoreThroat = null;
  public hasTroubleBreathing = null;
  public hasTasteAndSmell = null;
  public hasBodySpots = null;
  public hasGastroSymtoms = null; */
  public hasFamilyWithSymtoms = null

  public isPayed = true
  public isVerified = true

  get isDisabled(): boolean {
    return (
      /*  !this.businessName ||
      !this.hasTravel ||
      !this.hasContactWithTravelers ||
      !this.hasFieber ||
      !this.hasCough ||
      !this.hasSoreThroat ||
      !this.hasTroubleBreathing ||
      !this.hasBodySpots ||
      !this.hasGastroSymtoms || */
      !this.hasFamilyWithSymtoms
    )
  }

  protected send() {
    const questions = {
      /*  q1: "Empresa donde trabaja (Domicilio, Teléfono)",
      r1: this.businessName,
      q2: "¿Ha viajado al exterior en los ultimos 14 días?",
      r2: this.hasTravel,
      q3: "¿Donde?",
      r3: this.whereHasTravel,
      q4: "¿Ha estado en contacto con viajeros?",
      r4: this.hasContactWithTravelers,
      q5: "¿Cuando?",
      r5: this.whenHasContact,
      symtomsQuestion: "¿Actualmente usted presenta alguno de estos sintomas?",
      q6: "Fiebre",
      r6: this.hasFieber,
      q7: "Tos",
      r7: this.hasCough,
      q8: "Dolor de garganta",
      r8: this.hasSoreThroat,
      q9: "Problemas para respirar",
      r9: this.hasTroubleBreathing,
      q10: "Ha perdido el gusto y/o el olfato",
      r10: this.hasTasteAndSmell,
      q11: "Ha tenido manchas en su cuerpo",
      r11: this.hasBodySpots,
      q12: "Sintomas gastrointestinales",
      r12: this.hasGastroSymtoms, */
      q13: '¿Te haz vacunado contra el COVID-19?',
      r13: this.hasFamilyWithSymtoms,
    }
    const assistantsData = {
      uuid: this.ticket.uuid,
      fullname: this.publicUserInfo.fullname,
      dni: this.publicUserInfo.dni,
      phone: this.publicUserInfo.phoneNumber,
      birthday: this.publicUserInfo.birthday,
      email: this.publicUserInfo.email,
      gender: this.publicUserInfo.gender,
      vacune: this.publicUserInfo.vacune,
      questions,
    }
    this.sendAssistantsInfo(assistantsData)
  }

  get action(): string {
    if (this.isPayed && this.isVerified) {
      return 'Ya verificado'
    } else if (this.isPayed && !this.isVerified) {
      return 'Marcar como verificado'
    } else {
      return 'Marcar como pagado y verificado'
    }
  }

  get btnStyles() {
    if (this.isPayed && this.isVerified) {
      return 'app-btn__text-verified'
    } else if (this.isPayed && !this.isVerified) {
      return 'app-btn__primary'
    } else {
      return 'app-btn__secondary'
    }
  }
}
