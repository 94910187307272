
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/shared/Header.vue'
import { State } from 'vuex-class'
import { deleteTicketAssistantId } from '@/services/seller.service'

import { eventDate, eventDateTime, idoDateToDateAndHours } from '@/helpers/date'
import { encryptQueryParams } from 'query-string-hash'

@Component({
  components: {
    Header,
  },
})
export default class PreAdvisoryHeader extends Vue {
  @State(({ userState }) => userState.ticket) ticket!: any

  showQR = false
  selectedAssistant = null
  showAssitants = false
  dialog = false

  get eventDateInit(): string {
    return eventDate(this.ticket.event.initial_date)
  }
  get eventDateEnd(): string {
    return eventDate(this.ticket.event.finish_date)
  }
  get eventDateTimeInit(): string {
    return eventDateTime(this.ticket.event.initial_date)
  }
  get eventDateTimeEnd(): string {
    return eventDateTime(this.ticket.event.finish_date)
  }

  get ticketSelected() {
    return this.ticket ? this.ticket : {}
  }

  get getAssistants() {
    return this.ticket?.assistants ?? []
  }

  eventDateProduct(date) {
    return eventDate(date)
  }

  eventTimeProduct(date) {
    return eventDateTime(date)
  }

  idoDateToDateAndHours(date) {
    return idoDateToDateAndHours(date)
  }

  setShowAssitants() {
    this.showAssitants = !this.showAssitants
  }

  async onDeleteTicketAssistantId(assistantId, index) {
    /**
     **/

    try {
      const { status } = await deleteTicketAssistantId(assistantId)
      if (status) {
        this.ticket?.assistants.splice(index, 1)
        Vue.$toast.success(`Asistente borrado correctamente!`)
      } else {
        Vue.$toast.warning(`Este asistente no se puede borrar!`)
      }
    } catch (error) {
      if (error.response.status == 422) {
        Vue.$toast.warning(error.response.data.message)
      }
      return false
    }
  }

  onShowQrAssistant(assistant) {
    /*
    this.ticket.message = (`Ticket válido desde ${this.eventTimeProduct(this.ticket.product.initial_date)} hasta ${ this.eventTimeProduct(this.ticket.product.finish_date) }`)

    this.selectedAssistant = assistant;
    this.showQR = true;
    this.dialog = true;
    */

    const query = {
      assistantFullName: assistant.fullname,
      eventName: this.ticket.event.name,
      eventLocation: this.ticket.event.location,
      qrData: assistant.qr_code,
      productName: this.ticket.product.name,
      productInitialDate: this.ticket.product.initial_date,
      productFinishDate: this.ticket.product.finish_date,
      productDescription: this.ticket.product.description,
      assistantDNI: assistant.dni,
      assistantGender: assistant.gender,
    }

    // Hash object (query params)
    const hash = encryptQueryParams(new URLSearchParams(query).toString())

    const routeData = this.$router.resolve({
      name: 'Generar Ticket QR',
      params: { data: hash },
    })

    window.open(routeData.href, '_blank')
  }
}
