
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue } from 'vue-property-decorator'
import { State, Action, Getter } from 'vuex-class'
import { Validate } from 'vuelidate-property-decorators'
import { required, minLength, email } from 'vuelidate/lib/validators'

/* HELPERS */
import { eventDate, eventDateTime } from '@/helpers/date'
import moment from 'moment'

@Component
export default class PublicRoleForm extends Vue {
  public menu = false
  @Validate({ required, minLength: minLength(8) }) fullname = ''
  @Validate({ required }) birthday = ''
  @Validate({ required }) custom_field_1 = ''
  @Validate({ required }) custom_field_2 = ''
  @Validate({ required }) custom_field_3 = ''
  @Validate({ required, minLength: minLength(8) }) dni = ''
  @Validate({ required }) gender = ''
  @Validate({ required }) vacune = ''
  @Validate({ required, minLength: minLength(8) }) phoneNumber = ''
  @Validate({ required, email }) email = ''
  @State(({ userState }) => userState.ticket) ticket!: any
  @Getter ticketHasQuotas!: Function
  @Action setIsPublicFormDisabled!: Function
  @Action setPublicUserInfo!: Function
  @Action sendAssistantsInfo!: Function

  public isPayed = true
  public isVerified = true
  public question = false
  showPicker = false
  public ageIsValid = null

  public genders = [
    { name: 'Masculino', value: 'male' },
    { name: 'Femenino', value: 'female' },
    { name: 'Otro', value: 'others' },
  ]

  public vacunes = [
    { name: 'Una dosis', value: 'Una dosis' },
    { name: 'Dos dosis', value: 'Dos dosis' },
    { name: 'Ninguna', value: 'Ninguna' },
  ]

  get eventDateInit(): string {
    return eventDate(this.ticket.event.initial_date)
  }
  get eventDateEnd(): string {
    return eventDate(this.ticket.event.finish_date)
  }
  get eventDateTimeInit(): string {
    return eventDateTime(this.ticket.event.initial_date)
  }
  get eventDateTimeEnd(): string {
    return eventDateTime(this.ticket.event.finish_date)
  }
  get action(): string {
    if (this.isPayed && this.isVerified) {
      return 'Ya verificado'
    } else if (this.isPayed && !this.isVerified) {
      return 'Marcar como verificado'
    } else {
      return 'Marcar como pagado y verificado'
    }
  }
  get btnStyles() {
    if (this.isPayed && this.isVerified) {
      return 'app-btn__text-verified'
    } else if (this.isPayed && !this.isVerified) {
      return 'app-btn__primary'
    } else {
      return 'app-btn__secondary'
    }
  }

  get CF1IsRequired() {
    if (this.ticket.event.custom_fields?.[0]) {
      if (
        this.ticket.event.custom_fields[0].required === 1 &&
        this.custom_field_1
      )
        return true
      if (
        this.ticket.event.custom_fields[0].required === 1 &&
        !this.custom_field_1
      )
        return false
      return true
    }
    return true
  }

  get CF2IsRequired() {
    if (this.ticket.event.custom_fields?.[1]) {
      if (
        this.ticket.event.custom_fields[1].required === 1 &&
        this.custom_field_2
      )
        return true
      if (
        this.ticket.event.custom_fields[1].required === 1 &&
        !this.custom_field_2
      )
        return false
      return true
    }
    return true
  }

  get CF3IsRequired() {
    if (this.ticket.event.custom_fields?.[2]) {
      if (
        this.ticket.event.custom_fields[2].required === 1 &&
        this.custom_field_3
      )
        return true
      if (
        this.ticket.event.custom_fields[2].required === 1 &&
        !this.custom_field_3
      )
        return false
      return true
    }
    return true
  }

  protected send() {
   

    const dateArray = this.birthday.split('/')
    const newDate = dateArray[1] + '/' + dateArray[0] + '/' + dateArray[2]

    const assistantsData = {
      uuid: this.ticket.uuid,
      fullname: this.fullname,
      dni: this.dni,
      phone: this.phoneNumber,
      birthday: newDate,
      email: this.email,
      gender: this.gender,
      vacune: this.vacune,
      question: this.question,
      custom_field_1: this.custom_field_1,
      custom_field_2: this.custom_field_2,
      custom_field_3: this.custom_field_3,
    }
    this.sendAssistantsInfo(assistantsData)
  }
  get isDisabled() {
    if (
      this.fullname &&
      this.birthday &&
      this.dni &&
      this.gender &&
      this.email &&
      this.phoneNumber &&
      this.CF1IsRequired &&
      this.CF2IsRequired &&
      this.CF3IsRequired
    ) {
      this.setIsPublicFormDisabled(false)
      return false
    } else {
      this.setIsPublicFormDisabled(true)
      this.setPublicUserInfo({})
      return true
    }
  }

  /* INPUT VALIDATIONS */
  get fullnameErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.fullname.$dirty) return errors
    !this.$v.fullname.required && errors.push('Este campo es requerido')
    !this.$v.fullname.minLength && errors.push('Escribe tu nombre completo')

    return errors
  }
  get birthdayErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.birthday.$dirty) return errors
    !this.$v.birthday.required && errors.push('Este campo es requerido')

    const dateArray = this.birthday.split('/')
    const newDate = dateArray[1] + '-' + dateArray[0] + '-' + dateArray[2]
    const date = new Date(newDate)
    const diff = moment().diff(date, 'years')
    /*
    if (this.ticket.product.min_age && this.ticket.product.min_age != 0) {
      const minAgeValidator =
        diff >= this.ticket.product.min_age ? true : false;
   

      !minAgeValidator &&
        errors.push(
          "La edad mínima es de " + this.ticket.product.min_age + " años"
        );

      this.ageIsValid = minAgeValidator ? true : false;
    } else {
      this.ageIsValid = true;
    }
*/
    return errors
  }

  get dniErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.dni.$dirty) return errors
    !this.$v.dni.required && errors.push('Este campo es requerido')
    !this.$v.dni.minLength && errors.push('El DNI no es válido')

    return errors
  }

  get cf1Errors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.custom_field_1.$dirty) return errors
    !this.$v.custom_field_1.required && errors.push('Este campo es requerido')
    return errors
  }

  get cf2Errors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.custom_field_2.$dirty) return errors
    !this.$v.custom_field_2.required && errors.push('Este campo es requerido')
    return errors
  }

  get cf3Errors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.custom_field_3.$dirty) return errors
    !this.$v.custom_field_3.required && errors.push('Este campo es requerido')
    return errors
  }

  get genderErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.gender.$dirty) return errors
    !this.$v.gender.required && errors.push('Este campo es requerido')
    return errors
  }

  get phoneNumberErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.phoneNumber.$dirty) return errors
    !this.$v.phoneNumber.required && errors.push('Este campo es requerido')
    !this.$v.phoneNumber.minLength &&
      errors.push('El número de teléfono no es válido')
    return errors
  }

  get emailErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.email.$dirty) return errors
    !this.$v.email.required && errors.push('Este campo es requerido')
    !this.$v.email.email && errors.push('El email no es válido')

    return errors
  }

  get vacuneErrors(): Array<string> {
    const errors: Array<string> = []
    if (!this.$v.vacune.$dirty) return errors
    !this.$v.vacune.required && errors.push('Este campo es requerido')
    return errors
  }
}
