
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import PublicRoleHeader from '@/components/PublicRole/PublicRoleHeader.vue'
import PublicRoleCard from '@/components/PublicRole/PublicRoleCard.vue'
import PublicRoleForm from '@/components/PublicRole/PublicRoleForm.vue'
import PublicRoleDeclaration from '@/components/PublicRole/PublicRoleDeclaration.vue'
import { State, Action, Getter } from 'vuex-class'

@Component({
  components: {
    PublicRoleHeader,
    PublicRoleForm,
    PublicRoleDeclaration,
    PublicRoleCard,
  },
})
export default class PublicRole extends Vue {
  @State(({ userState }) => userState.ticket) ticket!: any

  async mounted() {
    document.title = 'App ' + this.ticket?.event?.name
  }
}
