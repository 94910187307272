
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/shared/Header.vue'
import { State } from 'vuex-class'

@Component({
  components: {
    Header,
  },
})
export default class PublicRoleHeader extends Vue {
  @State(({ userState }) => userState.ticket) ticket!: any
}
